import CTA from "components/CTA";
import ContactUs from "components/ContactUs";
import Footer from "components/Footer";
import Hero from "components/Hero";
import HeroGeneral from "components/HeroGeneral";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Outlet, useLocation } from "react-router-dom";

function MainLayout() {
  useDocumentTitle();
  const location = useLocation();

  return (
    <main className="">
      {location.pathname === "/" ? <Hero /> : <HeroGeneral />}
      <Outlet />
      <CTA />
      <ContactUs />
      <Footer />
    </main>
  );
}

export default MainLayout;
