import * as React from "react"

function Mail(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4215_5205)">
        <path
          d="M21.37 27.038h-15a3.75 3.75 0 01-3.75-3.75v-10a1.25 1.25 0 00-2.5 0v10a6.25 6.25 0 006.25 6.25h15a1.25 1.25 0 100-2.5zm5-22.5H8.87a3.75 3.75 0 00-3.75 3.75v12.5a3.75 3.75 0 003.75 3.75h17.5a3.75 3.75 0 003.75-3.75v-12.5a3.75 3.75 0 00-3.75-3.75zm-.513 2.5l-7.35 7.35a1.248 1.248 0 01-1.775 0l-7.35-7.35h16.475zm1.762 13.75a1.25 1.25 0 01-1.25 1.25H8.87a1.25 1.25 0 01-1.25-1.25V8.75l7.35 7.35a3.75 3.75 0 005.3 0l7.35-7.35v12.038z"
          fill="#008F8F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4215_5205">
          <path fill="#fff" transform="translate(.12)" d="M0 0H30V30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Mail
