import useDocumentTitle from "hooks/useDocumentTitle";
import { useState } from "react";
import MenuIcon from "./MenuIcon";
import styles from "./hero.module.css";


export default function HeroGeneral() {

  const [showNav, setShowNav] = useState(false);
  const {title} = useDocumentTitle();

  return (
    <section className={`padding__y padding__x ${styles.hero}`}>
      <header>
        <img className="" src="/logo.png" alt="3Scorers" />
        <nav className={`${showNav ? styles.show : ''}`}>
          <a className={styles.active} href="/">Home</a>
          <a className="" href="/#how-to-predict">How To Predict</a>
          <a className="" href="/#appfeatures">App Features</a>
          <a className="" href="/#contact-us">Contact Us</a>
        </nav>
        <MenuIcon className={styles.menu} onClick={() => setShowNav(n => !n)}/>
      </header>

      <div className={styles.hero__content}>
        <h1 className={styles.hero__title}>
          {title}
        </h1>
      </div>
    </section>
  )
}
