import { useEffect } from "react";
import styles from "./howtopredict.module.css";
import { tns } from "tiny-slider";

const images = [
  "/images/app/prediction.png",
  "/images/app/h2h.png",
  "/images/app/line-up.png",
]

export default function HowToPredict() {

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const slider = tns({
      container: "#slides",
      items: 1,
      responsive: {
        1024: {
          speed: 3000
        }
      },
      slideBy: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      mouseDrag: true,
      nav: true,
      navPosition: "bottom",
      controls: false,
      speed: 1000,
    });

  }, []);
  
  return (
    <section id="how-to-predict" className={`padding__y padding__x ${styles.howtopredict}`}>
        <div className={`space-y-1`}>
            <div className={styles.header}>
                <p className={styles.subtitle}>Get to know </p>
                <h1 className={styles.title}>How To Predict</h1>
            </div>

            <div className={`${styles.body} space-y-1`}>
                <div className={styles.h2p__details}>
                  <p className={styles.h2p__headings}>Step 1: Register and Set Up Your Account</p>
                  <p className={styles.h2p__desc}>Download the 3Scorers app from the App Store or Google Play Store. Open the app and follow the prompts to register and set up your account.</p>
                </div>
                <div className={styles.h2p__details}>
                  <p className={styles.h2p__headings}>Step 2: Select a Match</p>
                  <p className={styles.h2p__desc}>From the predict screen, you’ll see a list of upcoming matches. Select the match you’re interested in.</p>
                </div>
                <div className={styles.h2p__details}>
                  <p className={styles.h2p__headings}>Step 3: Choose Your Players</p>
                  <p className={styles.h2p__desc}>Once you’ve selected a match, it’s time to choose your players. You can select any three players from the two teams playing the match. You can choose all three players from one team, or split them between the two teams - it’s up to you!</p>
                </div>
                <div className={styles.h2p__details}>
                  <p className={styles.h2p__headings}>Step 4: Choose Your Captain</p>
                  <p className={styles.h2p__desc}>After selecting your players, choose one of them to be your captain. Your captain earns double points, so choose wisely!</p>
                </div>
                <div className={styles.h2p__details}>
                  <p className={styles.h2p__headings}>Step 5: Confirm Your Selections</p>
                  <p className={styles.h2p__desc}>Once you’re happy with your selections, confirm your team. Remember, you can’t change your players or captain once the match has been selected.</p>
                </div>
                
                {/* <p className="">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci lectus nec proin aenean semper suspendisse in. Viverra aenean sagittis tincidunt id suspendisse ultricies.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci lectus nec proin aenean semper suspendisse in. Viverra aenean sagittis tincidunt id suspendisse ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci lectus nec proin aenean semper suspendisse in. Viverra aenean sagittis tincidunt id suspendisse ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut orci lectus nec proin aenean semper suspendisse in. Viverra aenean sagittis tincidunt id suspendisse ultricies.
                </p> */}
            </div>
        </div>

        <div className={styles.slides}>
          <div id="slides">
            {images.map((image, index) => (
              <img className={styles.slide_image} key={index} src={image} alt=""/>
            ))}
          </div>
        </div>

    </section>
  )
}
