import MainLayout from "layouts/MainLayout";
import ErrorPage from "pages/ErrorPage";
import Homepage from "pages/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TOS from "pages/TOS";
import Disclaimer from "pages/Disclaimer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Homepage />, handle: "Homepage" },
      { path:"privacy-policy", element: <PrivacyPolicy />, handle: "Privacy Policy" },
      { path:"terms-of-service", element: <TOS />, handle: "Terms of Service" },
      { path:"disclaimer", element: <Disclaimer />, handle: "Disclaimer" },
    ],
  },
]);


function App() {
  return <RouterProvider router={router}/>;
}

export default App;
