
function Twitter(props:any) {
  return (
    <svg
      width={31}
      height={31}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.239 5.88a1.25 1.25 0 00-1.888-1.074 9.348 9.348 0 01-2.342.991 6.44 6.44 0 00-4.217-1.553 6.54 6.54 0 00-6.53 6.33 13.79 13.79 0 01-8.517-4.905 1.264 1.264 0 00-1.071-.457 1.248 1.248 0 00-.982.625 6.595 6.595 0 00-.303 5.961l-.002.002a1.302 1.302 0 00-.619 1.111c-.002.184.009.368.033.55a6.482 6.482 0 001.96 4.14 1.248 1.248 0 00-.083.962 6.506 6.506 0 002.953 3.653 9.332 9.332 0 01-4.487.56 1.25 1.25 0 00-.831 2.291 16.178 16.178 0 008.762 2.577A15.985 15.985 0 0026.656 15.94c.441-1.48.667-3.014.669-4.558l-.001-.25a7.213 7.213 0 001.915-5.252zm-4.133 4.015c-.207.244-.311.559-.292.878.012.206.011.414.011.609a13.53 13.53 0 01-.568 3.851 13.355 13.355 0 01-13.182 9.911c-1.074 0-2.144-.126-3.188-.376a11.85 11.85 0 003.678-1.954 1.25 1.25 0 00-.752-2.233 4.01 4.01 0 01-2.768-1.168c.187-.035.373-.08.557-.132a1.25 1.25 0 00-.1-2.43 3.998 3.998 0 01-2.813-2.156c.226.03.453.05.681.057a1.276 1.276 0 001.23-.87 1.25 1.25 0 00-.5-1.421 3.994 3.994 0 01-1.773-3.589 16.267 16.267 0 0010.26 4.35 1.276 1.276 0 001.023-.448 1.252 1.252 0 00.257-1.086 3.946 3.946 0 01-.108-.91 4.038 4.038 0 014.033-4.034 3.98 3.98 0 012.93 1.276 1.24 1.24 0 001.152.372c.513-.1 1.02-.234 1.515-.402a8.353 8.353 0 01-1.283 1.905z"
        fill="#008F8F"
      />
    </svg>
  )
}

export default Twitter
