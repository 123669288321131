import styles from "./emblems.module.css";

const emblems:string[] = [
    "/images/emblem1.png",
    "/images/emblem2.png",
    "/images/emblem3.png",
    "/images/emblem4.png",
    "/images/emblem5.png",
    "/images/emblem6.png",
    "/images/emblem7.png",
    "/images/emblem8.png",
    "/images/emblem9.png",
]

export default function Emblems() {
  return (
    <section className={`padding__y`}>
        <div className={styles.container}>
            {emblems.map((emblem, index) => (
                <div key={index} className={styles.emblem}>
                    <img
                        src={emblem}
                        alt="Emblem"
                    />
                </div>
            ))}
        </div>
    </section>
  )
}
