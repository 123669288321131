import { useEffect } from "react";
import { tns } from "tiny-slider";
import "tiny-slider/dist/tiny-slider.css";
import FeatureIcon1 from "../icons/FeatureIcon1";
import FeatureIcon2 from "../icons/FeatureIcon2";
import FeatureIcon3 from "../icons/FeatureIcon3";
import styles from "./appfeature.module.css";

const features = [
  {
    icon: FeatureIcon1,
    title: "Live Matches Feature",
    details:
      "Experience the excitement of your favorite team’s game with 3Scorers. Get real-time updates on goals, fouls, substitutions, VAR decisions, and more through a detailed overview and live statistics.",
  },
  {
    icon: FeatureIcon2,
    title: "New Matches Feature",
    details:
      "Explore over 800 leagues with 3Scorers, previewing upcoming matches and accessing line-ups an hour before kick-off. View team standings, head-to-head records, and top-rated players with detailed skill attributes.",
  },
  {
    icon: FeatureIcon3,
    title: "Past Matches Feature",
    details:
      "Relive soccer's magic with 3Scorers! Rewind and experience unforgettable moments from past matches. Highlights, stats, and global memories – every game tells a timeless story.",
  },
];

const images = [
  "/images/app/prediction.png",
  "/images/app/h2h.png",
  "/images/app/line-up.png",
  "/images/app/standings.png",
  "/images/app/new-matches.png",
]

export default function AppFeatures() {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const slider = tns({
      container: "#app-features-slider",
      items: 1,
      responsive: {
        760: {
          items: 1.7
        },
        1024: {
          items: 2.3,
          speed: 3000,
        }
      },
      slideBy: 1,
      center: true,
      autoplay: true,
      autoplayTimeout: 5000,
      mouseDrag: true,
      nav: false,
      autoplayHoverPause: true,
      controls: false,
      speed: 1000,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const slider2 = tns({
      container: "#app_fts_slides",
      items: 1,
      responsive: {
        760: {
          items: 1.7
        },
        1024: {
          items: 1,
          edgePadding: 500,
          speed: 3000,
        }
      },
      slideBy: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      // loop: true,
      mouseDrag: true,
      // nav: true,
      navPosition: "bottom",
      controls: false,
      speed: 1000,
    });
    
  }, []);

  return (
    <section id="appfeatures" className={`padding__y space-y-2 ${styles.appfeatures}`}>
      <div className={`space-y-1`}>
        <div className={styles.header}>
          <p className={styles.subtitle}>Our</p>
          <h1 className={styles.title}>App Features</h1>
        </div>
      </div>

      <div id="app-features-slider" className={styles.slides}>
        {features.map(({ icon: Icon, title, details }, index) => (
          <div key={index} className="">
            <div className={styles.card}>
              <div className={styles.icon}>
                <Icon />
              </div>
              <div className={`space-y-0.5`}>
                <p className={styles.card__title}>{title}</p>
                <p className={styles.card__details}>{details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.app_fts_slides}>
          <div id="app_fts_slides">
            {images.map((image, index) => (
              <img className={styles.app_fts_slides_image} key={index} src={image} alt=""/>
            ))}
          </div>
        </div>
    </section>
  );
}
