import styles from "./cta.module.css"

export default function CTA() {
  return (
    <section id="download-app" className={`padding__y`}>
      <div className={styles.cta__container}>
        <div className={styles.cta__image}>
          <img
            src="holding-phone.png"
            alt="Woman holding phone with 3Scorers App open"
          />
        </div>

        <div className={styles.cta__details}>
          <h1 className={styles.cta__heading}>
            3Scorers <br />
            Mobile <br />
            App
          </h1>
          <p className={styles.cta__description}>
            3Scorers is a fantasy football app where you select three players from any two teams playing a match. Your selected players earn points based on their real-life performance in that match. The better your players perform, the more points you earn!
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.stemos.app3scorers">
            <img
              className={styles.cta__badge}
              src="/google-play-badge.png"
              alt="Install 3Scorers from Google Play"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
