function FeatureIcon2(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path d="M3 0h19l-3 6H0l3-6z" fill="#51FFFF" />
      <path d="M3 8h19l-3 6H0l3-6zM3 16h19l-3 6H0l3-6z" fill="#008F8F" />
    </svg>
  );
}

export default FeatureIcon2;
