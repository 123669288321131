import AppFeatures from "components/AppFeatures";
import Emblems from "components/Emblems";
import HowToPredict from "components/HowToPredict";

function Homepage() {
  return (
    <div className="App">
        <Emblems />
        <AppFeatures />
        <HowToPredict />
    </div>
  )
}

export default Homepage