import Instagram from "../icons/Instagram";
import Telegram from "../icons/Telegram";
import Twitter from "../icons/Twitter";
import styles from "./footer.module.css";

export default function Footer() {
  return (
    <footer className={`${styles.container} padding__t`}>
        <div className="">
            <img className={styles.footer__logo} src="/logo.png" alt="3Scorers" />
            <p className="">
                3Scorers is a fantasy football app where you select three players from any two teams playing a match. Your selected players earn points based on their real-life performance in that match. The better your players perform, the more points you earn!
            </p>
        </div>
        
        <div className="">
            <p className={styles.list__title}>Quick Links</p>

            <div className={styles.links__list}>
                <div className={styles.link}>
                    <a href="/">Home</a>
                </div>
                <div className={styles.link}>
                    <a href="/#about-us">About Us</a>
                </div>
                <div className={styles.link}>
                    <a href="/#how-to-predict">How to Predict</a>
                </div>
                <div className={styles.link}>
                    <a href="/#appfeatures">App Features</a>
                </div>
                <div className={styles.link}>
                    <a href="/#download-app">Download App</a>
                </div>
                <div className={styles.link}>
                    <a href="/#contact-us">Contact Us</a>
                </div>
            </div>
        </div>
        
        <div className="">
            <p className={styles.list__title}>Policies</p>

            <div className={styles.links__list}>
                <div className={styles.link}>
                    <a href="/terms-of-service">Terms of Service</a>
                </div>
                <div className={styles.link}>
                    <a href="/privacy-policy">Privacy Policy</a>
                </div>
                <div className={styles.link}>
                    <a href="/community-rules">Community Rules</a>
                </div>
                <div className={styles.link}>
                    <a href="/disclaimer">Disclaimer</a>
                </div>
            </div>
        </div>
        
        <div className="">
            <p className={styles.list__title}>Follow Us</p>

            <div className={styles.follow__list}>
                <a className="" href="https://www.instagram.com/3scorers?igsh=YmU3YnJkbHpwbWtp">
                    <Instagram />
                </a>
                <a className="" href="https://twitter.com/3Scorers">
                    <Twitter />
                </a>
                <a className="" href="/#">
                    <Telegram />
                </a>
            </div>
        </div>
    </footer>
  )
}
