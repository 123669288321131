import Instagram from "../icons/Instagram";
import Mail from "../icons/Mail";
import Telegram from "../icons/Telegram";
import Twitter from "../icons/Twitter";
import styles from "./contact-us.module.css";

export default function ContactUs() {
  return (
    <section id="contact-us" className={`padding__y padding__x space-y-2`}>
      <div className={styles.header}>
        <h1 className={styles.heading}>Contact Us</h1>
        <p className={styles.subtitle}>
            Questions or suggestions? Reach out to us on social media or use the message box below. We welcome your feedback and are happy to assist.
        </p>
      </div>

      <div className={styles.body}>
        <div className={`${styles.social_handles} space-y-2`}>
            <div className={styles.card}>
                <div className={styles.icon}>
                    <Instagram />
                </div>
                <div className={styles.card__body}>
                    <p className={styles.card__title}>Instagram</p>       
                    <p className={styles.card__details}>@3Scorers</p>       
                </div>
            </div>
            
            <div className={styles.card}>
                <div className={styles.icon}>
                    <Twitter />
                </div>
                <div className={styles.card__body}>
                    <p className={styles.card__title}>Twitter</p>       
                    <p className={styles.card__details}>@3Scorers</p>       
                </div>
            </div>
            
            <div className={styles.card}>
                <div className={styles.icon}>
                    <Telegram />
                </div>
                <div className={styles.card__body}>
                    <p className={styles.card__title}>Telegram</p>       
                    <p className={styles.card__details}>3Scorers</p>       
                </div>
            </div>
            
            <div className={styles.card}>
                <div className={styles.icon}>
                    <Mail />
                </div>
                <div className={styles.card__body}>
                    <p className={styles.card__title}>Email Address</p>       
                    <p className={styles.card__details}>3Scorers@gmail.com</p>       
                </div>
            </div>
        </div>

        <form className={`space-y-1`}>
            <div className={styles.form__group}>
                <label htmlFor="fullname">Fullname</label>
                <input type="text" name="fullname" />
            </div>
            <div className={styles.form__group}>
                <label htmlFor="email">Email</label>
                <input type="text" name="email" />
            </div>
            <div className={styles.form__group}>
                <label htmlFor="message">Message</label>
                <textarea rows={8} name="message"/>
            </div>
            <div className={styles.button__group}>
                <button type="submit">Send Message</button>
            </div>
        </form>
      </div>
    </section>
  );
}
