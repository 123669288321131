function PrivacyPolicy() {
  return (
    <section className="">
      <iframe
        src="https://doc-hosting.flycricket.io/3scorers-privacy-policy/7af44d73-121d-4a7e-b027-1b8467637219/privacy"
        className="iframe"
        title="3Scorers Privacy Policy"
      ></iframe>
    </section>
  );
}

export default PrivacyPolicy;
