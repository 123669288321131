
function Telegram(props:any) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.83 3.035a2.56 2.56 0 00-2.597-.406l-22.4 9.045a2.554 2.554 0 00.13 4.773l4.53 1.576 2.527 8.352c.034.111.084.217.148.315.01.015.023.026.033.04a1.236 1.236 0 00.352.333c.118.078.25.136.388.17l.015.001.008.004c.083.017.168.025.253.025.008 0 .015-.004.024-.004.128-.002.255-.024.376-.065.028-.01.052-.026.08-.037.09-.038.176-.086.255-.144l.19-.16 3.378-3.73 5.038 3.903c.443.346.99.533 1.551.534a2.568 2.568 0 002.51-2.041l4.079-20.02a2.537 2.537 0 00-.867-2.464zM11.714 18.42a1.242 1.242 0 00-.34.632l-.388 1.88-.98-3.241 5.082-2.647-3.374 3.376zm10.377 6.63l-5.953-4.613a1.252 1.252 0 00-1.692.15l-1.082 1.193.382-1.858L22.6 11.07a1.25 1.25 0 00-1.461-1.992L8.43 15.693l-4.655-1.704L26.249 5l-4.16 20.051z"
        fill="#008F8F"
      />
    </svg>
  )
}

export default Telegram
