function Disclaimer() {
  return (
    <section className="">
      <iframe
        src="https://doc-hosting.flycricket.io/disclaimer-notices/2855325c-c40c-4054-9e5c-2d5eae02d177/other"
        className="iframe"
        title="3Scorers Privacy Policy"
      ></iframe>
    </section>
  );
}

export default Disclaimer;
