function TOS() {
  return (
    <section className="">
      <iframe
        src="https://doc-hosting.flycricket.io/3scorers-terms-of-use/34e4e61c-0cf5-47d8-acc2-a813f19e9dbb/terms"
        className="iframe"
        title="3Scorers Terms of Service"
      ></iframe>
    </section>
  );
}

export default TOS;
