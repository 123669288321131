function FeatureIcon1(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4962_370)">
        <path d="M18 10h6l-3-4-3 4zm0 4h6l-3 4-3-4z" fill="#008F8F" />
        <path d="M1 0h6v24H1a1 1 0 01-1-1V1a1 1 0 011-1z" fill="#51FFFF" />
        <path d="M9 0h6a1 1 0 011 1v22a1 1 0 01-1 1H9V0z" fill="#008F8F" />
      </g>
      <defs>
        <clipPath id="clip0_4962_370">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FeatureIcon1;
