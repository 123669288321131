import { useState } from "react";
import HeroImage from "./HeroImage";
import MenuIcon from "./MenuIcon";
import styles from "./hero.module.css";


export default function Hero() {

  const [showNav, setShowNav] = useState(false);

  return (
    <section className={`padding__y padding__x ${styles.hero}`}>
      <header>
        <img className="" src="/logo.png" alt="3Scorers" />
        <nav className={`${showNav ? styles.show : ''}`}>
          <a className={styles.active} href="/">Home</a>
          <a className="" href="/#how-to-predict">How To Predict</a>
          <a className="" href="/#appfeatures">App Features</a>
          <a className="" href="/#contact-us">Contact Us</a>
        </nav>
        <MenuIcon className={styles.menu} onClick={() => setShowNav(n => !n)}/>
      </header>

      <div className={styles.hero__content}>
        <div className={styles.hero__intro}>
          <h1 className="">
            <span>Join. Predict.</span><br />
            Win cash prizes!
          </h1>
          <p className="">
            3Scorers is a fantasy football app where you select three players from any two teams playing a match. Your selected players earn points based on their real-life performance in that match. The better your players perform, the more points you earn!
          </p>
          <a className={styles.get__app} href="/#download-app">Get App</a>
        </div>

        <HeroImage className={styles.hero__img} />
      </div>
    </section>
  )
}
